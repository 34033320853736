import { padStart } from "lodash";
import { useEffect, useState, useRef, useMemo } from "react";
import { debounce } from "lodash";
import classNames from "classnames";
import { calc } from "./calc.js";
import eur from "./eur.js";
import * as React from "react";
import host from "./host.js";
import {
	flexRender,
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";

import useLocalStorage from "./use-local-storage.js";

const FILTER_NON_FAVORITE = "0";
const FILTER_FAVORITE = "1";
const FILTER_RDV = "2";
const FILTER_VENDU = "3";

let firstView = true;

window.onhashchange = function () {
	firstView = true;
};

function Filter({ column, table }) {
	const firstValue = table
		.getPreFilteredRowModel()
		.flatRows[0]?.getValue(column.id);

	const columnFilterValue = column.getFilterValue();

	if (column.columnDef.Filter) {
		const C = column.columnDef.Filter;
		return <C column={column} table={table} />;
	}

	return typeof firstValue === "number" ? (
		<div>
			<div className="min-max-filter">
				<DebouncedInput
					type="number"
					min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
					max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
					value={columnFilterValue?.[0] ?? ""}
					onChange={(value) =>
						column.setFilterValue((old) => [value, old?.[1]])
					}
					placeholder={`Min ${
						column.getFacetedMinMaxValues()?.[0]
							? `(${column.getFacetedMinMaxValues()?.[0]})`
							: ""
					}`}
					className="w-24 border shadow rounded"
				/>
				<DebouncedInput
					type="number"
					min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
					max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
					value={columnFilterValue?.[1] ?? ""}
					onChange={(value) =>
						column.setFilterValue((old) => [old?.[0], value])
					}
					placeholder={`Max ${
						column.getFacetedMinMaxValues()?.[1]
							? `(${column.getFacetedMinMaxValues()?.[1]})`
							: ""
					}`}
					className="w-24 border shadow rounded"
				/>
			</div>
		</div>
	) : (
		<>
			<DebouncedInput
				type="text"
				value={columnFilterValue ?? ""}
				onChange={(value) => column.setFilterValue(value)}
				placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
				className="w-36 border shadow rounded"
				list={column.id + "list"}
			/>
		</>
	);
}

function useDebounce(callback, time = 1000) {
	const ref = useRef();

	useEffect(() => {
		ref.current = callback;
	}, [callback]);

	const debouncedCallback = useMemo(() => {
		function func() {
			ref.current?.();
		}
		return debounce(func, time);
	}, [time]);

	return debouncedCallback;
}

function DebouncedInput({ value: initialValue, onChange, debounce = 500 }) {
	const [value, setValue] = useState(initialValue);

	const debouncedRequest = useDebounce(() => {
		// send request to the backend
		// access to latest state here
		onChange(value);
	}, debounce);

	useEffect(
		function () {
			setValue(initialValue);
		},
		[initialValue],
	);

	function myOnChange(e) {
		const value = e.target.value;
		setValue(value);

		debouncedRequest();
	}

	return <input onChange={myOnChange} value={value} />;
}

function stableSort(arr, compare) {
	return arr
		.map((item, index) => ({ item, index }))
		.sort((a, b) => compare(a.item, b.item) || a.index - b.index)
		.map(({ item }) => item);
}

function toggleComment(value, pos) {
	var lineArr = value.split("\n");
	var numChars = 0;
	let endStr = "";
	let replaced = false;
	let change = 0;
	for (var i = 0; i < lineArr.length; i++) {
		numChars += lineArr[i].length + 1;
		if (numChars > pos.start && !replaced) {
			replaced = true;
			if (lineArr[i].indexOf("//") === 0) {
				const newLine = lineArr[i].replace(/^\/\/ */g, "");
				change = newLine.length - lineArr[i].length;
				endStr += lineArr[i].replace(/^\/\/ */g, "") + "\n";
				continue;
			}
			endStr += "// " + lineArr[i] + "\n";
			change = 3;
			continue;
		}
		endStr += lineArr[i] + "\n";
	}
	return { value: endStr, change };
}

function getCursorPos(input) {
	if ("selectionStart" in input && document.activeElement === input) {
		return {
			start: input.selectionStart,
			end: input.selectionEnd,
		};
	} else if (input.createTextRange) {
		var sel = document.selection.createRange();
		if (sel.parentElement() === input) {
			var rng = input.createTextRange();
			rng.moveToBookmark(sel.getBookmark());
			for (
				var len = 0;
				rng.compareEndPoints("EndToStart", rng) > 0;
				rng.moveEnd("character", -1)
			) {
				len++;
			}
			rng.setEndPoint("StartToStart", input.createTextRange());
			for (
				var pos = { start: 0, end: len };
				rng.compareEndPoints("EndToStart", rng) > 0;
				rng.moveEnd("character", -1)
			) {
				pos.start++;
				pos.end++;
			}
			return pos;
		}
	}
	return -1;
}

// Create an editable cell renderer
function EditableCell({ row: { original }, column: { id }, table }) {
	const { updtDT, single } = table.options;
	// We need to keep and update the state of the cell normally
	const myVal = original[id] || "";
	const [value, setValue] = React.useState(myVal);
	const [pos, setPos] = React.useState(null);

	const textareaElement = useRef();

	React.useEffect(() => {
		setValue(myVal);
	}, [myVal]);

	useEffect(() => {
		if (pos) {
			textareaElement.current.selectionStart = pos.start;
			textareaElement.current.selectionEnd = pos.start;
		}
	}, [pos]);

	if (!single) {
		if (!original[id] || typeof original[id] !== "string") {
			return original[id];
		}
		return original[id].split("\n").reduce(function (els, x, i, total) {
			els.push(<span key={`span-${i}`}>{x}</span>);
			if (i + 1 < total.length) {
				els.push(<br key={`br-${i}`} />);
			}
			return els;
		}, []);
	}

	function onChange(e) {
		setValue(e.target.value);
	}

	// We'll only update the external data when the input is blurred
	function onBlur() {
		updtDT(original.uuid, id, value);
	}

	return (
		<textarea
			value={value}
			ref={textareaElement}
			name=""
			rows="3"
			onKeyDown={(e) => {
				if (e.key === "/" && e.ctrlKey === true) {
					e.preventDefault();
					const pos = getCursorPos(e.target);
					const val = toggleComment(value, pos);
					setValue(val.value);
					setPos({ start: pos.start + val.change });
				}
			}}
			onChange={onChange}
			onBlur={onBlur}
		/>
	);
}

function EditablePctCell({ row: { original }, column: { id }, table }) {
	const { updtDT, single } = table.options;
	// We need to keep and update the state of the cell normally
	const myVal = original[id] || "";
	const [value, setValue] = React.useState(myVal);

	const textareaElement = useRef();

	React.useEffect(() => {
		setValue(myVal);
	}, [myVal]);

	if (!single) {
		if (!original[id] || typeof original[id] !== "string") {
			return original[id];
		}
		return original[id].split("\n").reduce(function (els, x, i, total) {
			els.push(<span key={`span-${i}`}>{x}</span>);
			if (i + 1 < total.length) {
				els.push(<br key={`br-${i}`} />);
			}
			return els;
		}, []);
	}

	// We'll only update the external data when the input is blurred
	function onBlur() {
		updtDT(original.uuid, id, value);
	}

	return (
		<label>
			<input
				value={typeof value === "number" ? value : 4}
				type="number"
				ref={textareaElement}
				onChange={(e) => setValue(parseInt(e.target.value || "0", 10))}
				onBlur={onBlur}
			/>
			%
		</label>
	);
}

function favFilterFn(row, columnId, value) {
	if (value === FILTER_NON_FAVORITE) {
		return !row.original.favorite;
	}
	if (value === FILTER_FAVORITE) {
		return !!row.original.favorite;
	}
	if (value === FILTER_RDV) {
		return !!row.original.etat_rdv;
	}
	if (value === FILTER_VENDU) {
		return row.original.etat_vente === "vendu";
	}
	return true;
}

function textFilterFn(row, columnId, value) {
	if (!value) {
		return true;
	}
	const val = row.getValue(columnId);
	if (!val) {
		return false;
	}
	return val.indexOf(value) !== -1;
}

function isEmpty(val) {
	if (typeof val === "string" && val.length > 0) {
		return false;
	}
	if (typeof val === "number") {
		return false;
	}
	return true;
}

function FavColumnFilter({ column }) {
	const { setFilterValue } = column;
	const columnFilterValue = column.getFilterValue() || "-";

	return (
		<select
			value={columnFilterValue}
			onChange={(e) => {
				setFilterValue(e.target.value);
			}}
			name="fav"
		>
			<option value="">-</option>
			<option value={FILTER_NON_FAVORITE}>Non favori</option>
			<option value={FILTER_FAVORITE}>Favori</option>
			<option value={FILTER_RDV}>RDV</option>
			<option value={FILTER_VENDU}>Vendu</option>
		</select>
	);
}

function DateRangeColumnFilter({ column }) {
	const { setFilterValue } = column;
	const columnFilterValue = column.getFilterValue() || [];
	const min = new Date(2023, 0, 1);
	const max = new Date();
	return (
		<div>
			<input
				value={columnFilterValue[0] || ""}
				type="date"
				min={min.toISOString().slice(0, 10)}
				onChange={(e) => {
					const val = e.target.value;
					setFilterValue((old = []) => [val ? val : undefined, old[1]]);
				}}
			/>
			to
			<input
				value={columnFilterValue[1] || ""}
				type="date"
				max={max.toISOString().slice(0, 10)}
				onChange={(e) => {
					const val = e.target.value;
					setFilterValue((old = []) => [old[0], val ? val : undefined]);
				}}
			/>
		</div>
	);
}

function DateCell(options) {
	return options.getValue().toLocaleDateString("fr-FR", {
		year: "numeric",
		month: "long",
		day: "numeric",
	});
}

const currentYear = new Date().getFullYear();

function shortDateFormatter(d) {
	let yearSuffix = "";
	if (d.getFullYear() !== currentYear) {
		yearSuffix = `/${d.getFullYear() - 2000}`;
	}
	return `${padStart(d.getDate(), 2, "0")}/${padStart(
		1 + d.getMonth(),
		2,
		"0",
	)}${yearSuffix}`;
}

const columns = [
	{
		id: "favorite",
		header: "Fav",
		Filter: FavColumnFilter,
		filterFn: "fav",
		embeddedView: true,
		sortDescFirst: true,
		accessorFn: (ad) => {
			if (ad.favorite) {
				if (ad.etat_vente === "vendu") {
					return 0.8;
				}
				if (ad.etat_rdv) {
					return 0.9;
				}
				return 1;
			}
			if (ad.etat_rdv) {
				return -1;
			}
			return 0;
		},
		cell: ({ row: { original: ad }, table }) => {
			const { updtDT } = table.options;
			return (
				<>
					{ad.favorite ? (
						<div
							className="fav cursor-pointer"
							onClick={() => updtDT(ad.uuid, "favorite", "")}
						>
							★
						</div>
					) : (
						<div
							className="notfav cursor-pointer"
							onClick={() =>
								updtDT(ad.uuid, "favorite", new Date().toISOString())
							}
						>
							★
						</div>
					)}
					{ad.archived ? (
						"Archivé"
					) : (
						<button
							className="btn-small"
							onClick={() =>
								updtDT(ad.uuid, "archived", new Date().toISOString())
							}
						>
							Archiver
						</button>
					)}
					<select
						name=""
						defaultValue={ad.etat_vente || "inconnu"}
						onChange={(e) => {
							updtDT(ad.uuid, "etat_vente", e.target.value);
						}}
					>
						<option value="inconnu">inconnu</option>
						<option value="vendu">vendu</option>
						<option value="libre">libre</option>
					</select>
					<br />
					<label>
						RDV
						<input
							type="checkbox"
							checked={!!ad.etat_rdv}
							onChange={(e) => {
								updtDT(ad.uuid, "etat_rdv", e.target.checked);
							}}
						/>
					</label>
				</>
			);
		},
	},
	{
		id: "prix",
		sortDescFirst: false,
		header: "Prix",
		accessorKey: "prix",
		enableSorting: true,
		cell: ({ row: { original }, table }) => {
			const { single } = table.options;
			function getP(x) {
				const price = eur("price")(x);
				if (single) {
					return `${shortDateFormatter(new Date(x.date))} : ${price}`;
				}
				return price;
			}
			return (
				<a href={original.url}>
					<>
						<div className="current-price">
							{getP(original.priceHistory[original.priceHistory.length - 1])}
						</div>
						{original.priceHistory
							.filter(function (x, i) {
								return i + 1 !== original.priceHistory.length;
							})
							.map(function (x, i) {
								return (
									<div className="old-price" key={i}>
										{getP(x)}
									</div>
								);
							})}
					</>
				</a>
			);
		},
	},
	{
		id: "surface",
		header: "Surface",
		accessorKey: "surface",
		cell: (options) => {
			return `${options.getValue()}m²`;
		},
	},
	{
		id: "prixm2",
		header: "Prix / m²",
		accessorKey: "prixm2",
		cell: ({ row: { original: ad } }) => {
			return eur("prixm2")(ad);
		},
	},
	{
		id: "localisation",
		header: "Localisation",
		accessorKey: "localisation",
		cell: ({ row: { original: ad } }) => {
			return (
				<>
					<div>{ad.localisation}</div>
					<div>
						<a href={`https://www.google.com/maps/?q=${ad.localisation}`}>
							GMaps
						</a>
					</div>
					<div>
						<a
							href={`https://www.leboncoin.fr/recherche?category=10&locations=${ad.localisation.replace(
								/ /g,
								"_",
							)}&furnished=1`}
						>
							Locations meublées
						</a>
					</div>
				</>
			);
		},
	},
	{
		id: "image",
		header: "Image",
		cell: ({ row: { original: ad } }) => {
			return (
				<a href={`#/ad/${ad.uuid}`}>
					{ad.localPictures.length === 0 ? (
						(ad.attachments || []).length > 0 ? (
							<img src={host(`attachments/${ad.attachments[0].path}`)} alt="" />
						) : (
							<div className="img-placeholder"> </div>
						)
					) : ad.localPictures.length > 1 ? (
						<img
							src={host(`pictures/${ad.localPictures[1]}?size=small`)}
							alt=""
						/>
					) : (
						<img
							src={host(`pictures/${ad.localPictures[0]}?size=small`)}
							alt=""
						/>
					)}
				</a>
			);
		},
	},
	{
		id: "vacances_locatives",
		embeddedView: true,
		header: "Vacances locatives",
		cell: EditablePctCell,
		nvalue: true,
	},
	{
		id: "renta",
		nvalue: true,
		embeddedView: true,
		enableColumnFilter: false,
		header: "R",
		viewModeSortOrder: 0,
		sortDescFirst: true,
		canSort: true,
		sortingFn: "renta_sort",
		accessorFn: (ad) => {
			if (
				isEmpty(ad.loyers_estimes) ||
				isEmpty(ad.travaux_estimes) ||
				isEmpty(ad.taxe_fonciere) ||
				isEmpty(ad.frais_copro) ||
				isEmpty(ad.frais_chauffage) ||
				isEmpty(ad.marge_nego)
			) {
				return "NA";
			}

			let num;
			let den;
			try {
				const vacancesLocatives = ad.vacances_locatives || 4;
				const res = `
					(
					${calc(ad.loyers_estimes)} * ${1 - vacancesLocatives / 100} * 12 -
					${calc(ad.taxe_fonciere)} -
					${calc(ad.frais_copro)} -
					${calc(ad.frais_chauffage)}
					) / (
						(${calc(ad.prix)} - ${calc(ad.marge_nego)}) * 1.075 +
						${calc(ad.travaux_estimes)};
					)
				`;
				console.log(res);

				num =
					calc(ad.loyers_estimes) * (1 - vacancesLocatives / 100) * 12 -
					calc(ad.taxe_fonciere) -
					calc(ad.frais_copro) -
					calc(ad.frais_chauffage);

				den =
					(calc(ad.prix) - calc(ad.marge_nego)) * 1.075 +
					calc(ad.travaux_estimes);
			} catch (e) {
				return e;
			}

			const renta = (num / den) * 100;
			return renta;
		},
		cell: (options) => {
			const gv = options.getValue();
			if (gv === "NA") {
				return "NA";
			}

			if (gv instanceof Error) {
				return (
					<span className="error" title={gv.message}>
						Error
					</span>
				);
			}

			return `${gv.toFixed(2)}%`;
		},
	},
	{
		id: "date_publication",
		header: "Publi",
		Filter: DateRangeColumnFilter,
		filterFn: "dateBetween",
		accessorFn: (ad) => {
			const date = ad.first_publication_date || ad.firstSeen;
			return new Date(date);
		},
		cell: DateCell,
	},
	{
		id: "firstSeen",
		header: "Vu",
		Filter: DateRangeColumnFilter,
		filterFn: "dateBetween",
		accessorFn: (ad) => {
			return new Date(ad.firstSeen);
		},
		cell: DateCell,
	},
	{
		id: "comment",
		embeddedView: true,
		accessorKey: "comment",
		header: "Commentaire",
		useTextarea: true,
		filterFn: "myText",
	},
	{
		id: "contacts_history",
		embeddedView: true,
		accessorKey: "contacts_history",
		header: "Contacts",
		useTextarea: true,
		filterFn: "myText",
	},
	{
		id: "quartier",
		embeddedView: true,
		accessorKey: "quartier",
		header: "Quartier",
		useTextarea: true,
		filterFn: "myText",
	},
	{
		id: "marge_nego",
		embeddedView: true,
		nvalue: true,
		enableColumnFilter: false,
		header: "Négo",
		accessorKey: "marge_nego",
		useTextarea: true,
	},
	{
		id: "loyers_estimes",
		embeddedView: true,
		viewModeSortOrder: 4,
		nvalue: true,
		header: "Loyers estimés",
		useTextarea: true,
	},
	{
		id: "travaux_estimes",
		enableColumnFilter: false,
		embeddedView: true,
		viewModeSortOrder: 5,
		nvalue: true,
		header: "Travaux Estimés",
		useTextarea: true,
		accessorKey: "travaux_estimes",
	},
	{
		id: "taxe_fonciere",
		embeddedView: true,
		enableColumnFilter: false,
		viewModeSortOrder: 1,
		nvalue: true,
		header: "Taxe fonciere",
		useTextarea: true,
		accessorKey: "taxe_fonciere",
	},
	{
		id: "frais_copro",
		embeddedView: true,
		viewModeSortOrder: 2,
		nvalue: true,
		header: "Frais copro",
		useTextarea: true,
	},
	{
		id: "frais_chauffage",
		embeddedView: true,
		viewModeSortOrder: 3,
		nvalue: true,
		header: "Frais chauffage",
		useTextarea: true,
	},
	{
		id: "energie",
		header: "Energie",
		accessorFn: (ad) => {
			return `${ad.energyClass || "-"}:${ad.gesClass || "-"}`;
		},
	},
	{
		id: "nblots",
		accessorKey: "nblots",
		header: "Type Lots étage",
		sortingFn: "lot_sort",
		cell: ({ row: { original: ad } }) => {
			return (
				<>
					{ad.nblots}
					<br />
					{ad.real_estate_type === "1" ? "Maison" : "Apt"} {ad.floor_number}
				</>
			);
		},
	},
	{
		id: "tel",
		header: "Tel",
		enableColumnFilter: false,
		accessorKey: "tel",
		useTextarea: true,
	},
	{
		embeddedView: true,
		id: "actions",
		header: "Actions",
		cell: ({ row: { original: ad }, table }) => {
			const { updtDT } = table.options;
			return (
				<>
					<button
						onClick={() => updtDT(ad.uuid, "deleted", new Date().toISOString())}
					>
						Supprimer
					</button>
					{ad.archived ? (
						"Archivé"
					) : (
						<button
							onClick={() =>
								updtDT(ad.uuid, "archived", new Date().toISOString())
							}
						>
							Archiver
						</button>
					)}
				</>
			);
		},
	},
	{
		id: "mandate_type",
		header: "Type de Mandat",
		cell: ({ row: { original: ad } }) => {
			let mt = "";
			if (ad.mandate_type === "exclusive") {
				mt = "Exclusif";
			}
			if (ad.mandate_type === "simple") {
				mt = "Simple";
			}
			return (
				<div>
					{mt}
					<div>{ad.owner_real_name}</div>
					<div>{ad.owner_name}</div>
					<div>{ad.owner_siren}</div>
				</div>
			);
		},
	},
];

columns.forEach(function (column) {
	if (column.useTextarea) {
		column.cell = EditableCell;
	}
});

function addDay(date) {
	date.setHours(date.getHours() + 24);
	return date;
}

function dateBetweenFilterFn(row, columnId, value) {
	if (!value[0] && !value[1]) {
		return true;
	}
	const val = row.getValue(columnId);
	if (value[0]) {
		const min = new Date(value[0]);
		if (+val < +min) {
			return false;
		}
	}
	if (value[1]) {
		const max = addDay(new Date(value[1]));
		if (+max < +val) {
			return false;
		}
	}
	return true;
}

dateBetweenFilterFn.autoRemove = (val) => !val;

function Table({ data, updtDT, single, localisationFilter, embedded }) {
	const [sorting, setSorting] = useLocalStorage("sorting_order", [
		{
			id: "prixm2",
			desc: false,
		},
	]);

	const tableState = {};

	let shownColumns = columns;
	if (/[0-9]{5}/.test(localisationFilter)) {
		shownColumns = shownColumns.filter(function ({ id }) {
			return id !== "localisation";
		});
	}

	shownColumns.forEach(function (col) {
		const { id } = col;
		if (id === "loyers_estimes") {
			col.header = "Loyers estimés";
		}

		if (id === "travaux_estimes") {
			col.header = "Travaux estimés";
		}
	});

	if (single) {
		const ad = data[0];

		shownColumns.forEach(function (col) {
			const { id } = col;
			if (id === "loyers_estimes") {
				col.header = `Loyers : ${Math.round(calc(ad.loyers_estimes))}€/mois`;
			}
			if (id === "travaux_estimes") {
				col.header = `Travaux : ${Math.round(calc(ad.travaux_estimes))}€`;
			}
		});

		shownColumns = shownColumns.filter(function ({ id }) {
			return id !== "image";
		});
		shownColumns = stableSort(shownColumns, function (a, b) {
			if (a.viewModeSortOrder != null && b.viewModeSortOrder != null) {
				if (a.viewModeSortOrder > b.viewModeSortOrder) {
					return 1;
				}
				if (a.viewModeSortOrder < b.viewModeSortOrder) {
					return -1;
				}
			}
			return 0;
		});
	} else {
		const columnIds = shownColumns.map(({ id }) => id);
		if (sorting.length > 0) {
			const oldLength = sorting.length;
			const newSorting = sorting.filter(function (sort) {
				return columnIds.indexOf(sort.id) !== -1;
			});
			if (newSorting.length < oldLength) {
				tableState.sorting = newSorting;
			} else {
				tableState.sorting = sorting;
			}
		}
	}

	const table = useReactTable({
		data,
		columns: shownColumns,
		filterFns: {
			dateBetween: dateBetweenFilterFn,
			fav: favFilterFn,
			myText: textFilterFn,
		},
		state: tableState,
		sortingFns: {
			lot_sort: ({ original: ad1 }, { original: ad2 }) => {
				const aVal = ad1.nblots;
				const bVal = ad2.nblots;
				if (!aVal) {
					return -1;
				}
				if (!bVal) {
					return 1;
				}
				if (aVal < bVal) {
					return -1;
				}
				return 1;
			},
			renta_sort: (a, b, id) => {
				const aVal = a.getValue(id);
				const bVal = b.getValue(id);
				if (aVal === "NA" && bVal !== "NA") {
					return -1;
				}
				if (aVal !== "NA" && bVal === "NA") {
					return 1;
				}
				if (aVal === "ERROR" && bVal !== "ERROR") {
					return -1;
				}
				if (aVal !== "ERROR" && bVal === "ERROR") {
					return -1;
				}
				if (aVal > bVal) {
					return 1;
				}
				return -1;
			},
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableSorting: data.length > 1,
		updtDT,
		single,
		embedded,
		lenOfData: data.length,
	});

	const [columnFilters, setColumnFilters] = useLocalStorage(
		"column_filters",
		[],
	);

	React.useEffect(() => {
		const timeout = setInterval(() => {
			setColumnFilters(table.getState().columnFilters);
		}, 5);
		return () => clearInterval(timeout);
	}, [table, setColumnFilters]);

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			if (firstView) {
				firstView = false;
				if (columnFilters.length > 0) {
					table.setColumnFilters(columnFilters);
				}
			}
		}, 5);
		return () => clearInterval(timeout);
	}, [table, setColumnFilters, columnFilters]);

	if (embedded) {
		const row = table.getRowModel().rows[0];
		const cells = row.getVisibleCells();
		const headers = table.getHeaderGroups()[0].headers;
		return (
			<div className="splitted-table-container">
				<div className="simple-table splitted-table">
					{headers.map((header, i) => {
						const { id } = header;
						const cell = cells[i];
						const embeddedView = cell.column.columnDef.embeddedView;
						if (!embeddedView) {
							return null;
						}
						const colId = cell.column.id;

						const cellClass = cell.column.columnDef.useTextarea
							? "simple-cell-textarea"
							: "simple-cell-content";
						return (
							<div key={id} className={`simple-table-${id}`}>
								<div className={`header header-${id} simple-header`}>
									{flexRender(
										header.column.columnDef.header,
										header.getContext(),
									)}
								</div>
								<div key={`**${id}**`} className={`${cellClass} cell-${colId}`}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	if (single) {
		const row = table.getRowModel().rows[0];
		const cells = row.getVisibleCells();
		const headers = table.getHeaderGroups()[0].headers;
		return (
			<>
				<div className="splitted-table-container">
					<div className="simple-table splitted-table">
						{headers.map((header, i) => {
							const { id } = header;
							const cell = cells[i];
							const nvalue = cell.column.columnDef.nvalue;
							if (nvalue) {
								return null;
							}
							const colId = cell.column.id;
							const cellClass = cell.column.columnDef.useTextarea
								? "simple-cell-textarea"
								: "simple-cell-content";
							return (
								<div key={id} className={`simple-table-${id}`}>
									<div className={`header header-${id} simple-header`}>
										{flexRender(
											header.column.columnDef.header,
											header.getContext(),
										)}
									</div>
									<div
										key={`**${id}**`}
										className={`${cellClass} cell-${colId}`}
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</div>
								</div>
							);
						})}
					</div>
					<div className="simple-table splitted-table">
						{headers.map((header, i) => {
							const { id } = header;
							const cell = cells[i];
							const colId = cell.column.id;
							const nvalue = cell.column.columnDef.nvalue;
							if (!nvalue) {
								return null;
							}
							const cellClass = cell.column.columnDef.useTextarea
								? "simple-cell-textarea"
								: "simple-cell-content";
							return (
								<div key={id} className={`simple-table-${id}`}>
									<div className={`header header-${id} simple-header`}>
										{flexRender(
											header.column.columnDef.header,
											header.getContext(),
										)}
									</div>
									<div
										key={id + "xxx"}
										className={`${cellClass} cell-${colId}`}
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</>
		);
	}
	return (
		<div className="p-2">
			<table className="ad-table">
				<thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								const { id } = header;
								return (
									<th key={id} className={`header header-${id}`}>
										{header.isPlaceholder ? null : (
											<div
												{...{
													className: header.column.getCanSort()
														? "cursor-pointer select-none"
														: "",
													onClick: header.column.getToggleSortingHandler(),
												}}
											>
												{flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)}
												{{
													asc: " 🔼",
													desc: " 🔽",
												}[header.column.getIsSorted()] ?? null}
											</div>
										)}
										{header.column.getCanFilter() ? (
											<div>
												<Filter column={header.column} table={table} />
											</div>
										) : null}
									</th>
								);
							})}
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map((row) => {
						return (
							<tr
								key={row.id}
								className={classNames({
									"row-favorite": row.original.favorite,
									"row-vendu": row.original.etat_vente === "vendu",
									"row-rdv": row.original.etat_rdv,
								})}
							>
								{row.getVisibleCells().map((cell) => {
									const colId = cell.column.id;
									const { id } = cell;
									return (
										<td key={id} className={`cell-${colId}`}>
											<div className="td-child">
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext(),
												)}
											</div>
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="flex items-center gap-2">
				<button
					onClick={() => table.setPageIndex(0)}
					disabled={!table.getCanPreviousPage()}
				>
					{"<<"}
				</button>
				<button
					onClick={() => table.previousPage()}
					disabled={!table.getCanPreviousPage()}
				>
					{"<"}
				</button>
				<button
					onClick={() => table.nextPage()}
					disabled={!table.getCanNextPage()}
				>
					{">"}
				</button>
				<button
					onClick={() => table.setPageIndex(table.getPageCount() - 1)}
					disabled={!table.getCanNextPage()}
				>
					{">>"}
				</button>
				<span className="flex items-center gap-1">
					<div>Page</div>
					<strong>
						{table.getState().pagination.pageIndex + 1} of{" "}
						{table.getPageCount()}
					</strong>
				</span>
				<span className="flex items-center gap-1">
					| Go to page:
					<input
						type="number"
						defaultValue={table.getState().pagination.pageIndex + 1}
						onChange={(e) => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0;
							table.setPageIndex(page);
						}}
						className="border p-1 rounded w-16"
					/>
				</span>
				<select
					value={table.getState().pagination.pageSize}
					onChange={(e) => {
						table.setPageSize(Number(e.target.value));
					}}
				>
					{[10, 20, 30, 40, 50].map((pageSize) => (
						<option key={pageSize} value={pageSize}>
							Show {pageSize}
						</option>
					))}
				</select>
			</div>
		</div>
	);
}

export default Table;
