function eur(accessor) {
	return function (ad) {
		const amount = ad[accessor];
		if (!amount) {
			return;
		}
		if (amount > 20000) {
			return Math.round(amount / 1000) + "k€";
		}
		return Math.round(amount) + "€";
	};
}

export default eur;
