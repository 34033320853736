const currentLoc = window.location.toString();
let serverHost = "";
if (currentLoc.indexOf("localhost") !== -1) {
	serverHost = "http://localhost:4747/";
}
if (currentLoc.indexOf("lbc.javascript-ninja.fr") !== -1) {
	serverHost = "/";
}
function host(url) {
	if (window.rentimmoSettings && window.rentimmoSettings.server) {
		return window.rentimmoSettings.server + url;
	}
	return serverHost + url;
}
export default host;
