import { sumBy } from "lodash";
import expressions from "angular-expressions";
const digitRegex = /(^|[\sa-zA-Z]+)([0-9.]+([0-9.* +-/]*))($|[\sk]+)/g;

const kRegex = /([0-9.])k/g;

export function getDigits(string) {
	string = string.replace(kRegex, "$1*1000");
	const res = [...string.matchAll(digitRegex)];
	let sum = 0;
	res.forEach(function (result) {
		const x = expressions.compile(result[2])();
		if (Math.abs(x) >= 10) {
			sum += x;
		}
	});
	return sum;
}

function dropComments(string) {
	return string.replace(/\/\/.*/, "");
}

export function calc(string) {
	if (typeof string === "number") {
		return string;
	}
	if (!string) {
		return 0;
	}
	try {
		return sumBy(
			string.split("\n"),
			function (s) {
				s = dropComments(s);
				digitRegex.lastIndex = 0;
				if (digitRegex.test(s)) {
					digitRegex.lastIndex = 0;
					const res = getDigits(s);
					if (Math.abs(res) < 10) {
						return 0;
					}
					return res;
				}
				digitRegex.lastIndex = 0;
				return 0;
			},
			0,
		);
	} catch (e) {
		console.log(e);
		return "error";
	}
}
