import "./App.scss";
import { useEffect, useState, useCallback, useRef } from "react";
import eur from "./eur.js";
import classNames from "classnames";
import { deburr } from "lodash";
import Table from "./table.js";
import host from "./host.js";

import { useParams, Route, Routes, HashRouter } from "react-router-dom";
import useLocalStorage from "./use-local-storage.js";

let titlePrefix = "Rentimmo";
if (window.location.toString().indexOf("localhost:3000") !== -1) {
	titlePrefix = "Localimmo";
}

if (localStorage.getItem("")) {
	localStorage.setItem("api_key", localStorage.getItem(""));
	localStorage.removeItem("");
}

function useStateCallback(initialState) {
	const [state, setState] = useState(initialState);
	const cbRef = useRef(null); // init mutable ref container for callbacks

	const setStateCallback = useCallback((state, cb) => {
		cbRef.current = cb; // store current, passed callback in ref
		setState(state);
	}, []); // keep object reference stable, exactly like `useState`

	useEffect(() => {
		// cb.current is `null` on initial render,
		// so we only invoke callback on state *updates*
		if (cbRef.current) {
			cbRef.current(state);
			cbRef.current = null; // reset callback after execution
		}
	}, [state]);

	return [state, setStateCallback];
}

function App() {
	const [ads, setAds] = useState([]);
	const [account, setAccount] = useState(null);
	const [archived, setArchived] = useStateCallback(false);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [fetchedData, setFetchedData] = useState(false);
	const [localisationFilter, setLocalisationFilter] = useLocalStorage(
		"ville",
		"",
	);
	const [apiKey, setApiKey] = useLocalStorage("api_key");
	const [intermediateApiKey, setIntermediateApiKey] = useState("");

	const fetchy = useCallback(
		(url, options = {}) => {
			options.headers ||= {};
			options.headers["x-api-key"] = apiKey;
			if (!(options.body instanceof FormData)) {
				options.headers["Content-Type"] = "application/json";
			}
			return fetch(host(url), options);
		},
		[apiKey],
	);

	async function fetchData(archived) {
		fetchy(`ads?archived=${archived}`)
			.then((res) => {
				if (res.status === 401) {
					return setApiKey("");
				}
				return res.json().then(
					(ads) => {
						setLoading(false);
						ads.forEach(function (ad) {
							ad.localisation_deburred = deburr(ad.localisation.toLowerCase());
						});
						setAds(ads);
						setFetchedData(true);
					},
					// Note: it's important to handle errors here
					// instead of a catch() block so that we don't swallow
					// exceptions from actual bugs in components.
					(error) => {
						setLoading(true);
						setError(error);
					},
				);
			})
			.then(function () {
				fetchy("auth")
					.then(function (res) {
						return res.text();
					})
					.then(function (account) {
						setAccount(account);
					});
			})
			.catch(function (error) {
				setLoading(true);
				setError(error);
			});
	}

	useEffect(() => {
		if (fetchedData || !apiKey) {
			return;
		}
		if (error) {
			return;
		}
		fetchData(archived);
	}, [apiKey, setApiKey, fetchy, fetchedData, archived]);

	if (!apiKey) {
		return (
			<div className="login screen-centered">
				<label>
					<span>Entrez votre clef</span>
					<input
						onChange={(e) => setIntermediateApiKey(e.target.value)}
						value={intermediateApiKey}
					/>
					<button onClick={() => setApiKey(intermediateApiKey)}>Valider</button>
				</label>
			</div>
		);
	}

	if (error) {
		return (
			<div className="screen-centered">
				Error {error.message} {error.stack}
			</div>
		);
	}
	if (loading) {
		return <div className="screen-centered">Loading ...</div>;
	}

	function updtDT(id, columnId, value) {
		// We also turn on the flag to not reset the page
		// setSkipPageReset(true)
		let newAd = null;

		if (columnId === "attachments") {
			const data = new FormData();

			for (const file of value) {
				data.append("files", file, file.name);
			}

			fetchy(`ads/${id}/upload`, {
				method: "POST",
				body: data,
			});
		} else {
			setAds((old) =>
				old.map((row) => {
					if (row.uuid === id) {
						newAd = {
							...row,
							[columnId]: value,
						};
						return newAd;
					}
					return row;
				}),
			);

			fetchy("ads/put", {
				method: "PUT",
				body: JSON.stringify(newAd),
			})
				.then((res) => res.json())
				.then(
					() => {},
					(error) => {
						setError(error);
					},
				);
		}
	}

	return (
		<HashRouter basename="/">
			<Routes>
				<Route
					path="/ad/:uuid"
					element={<AdView ads={ads} updtDT={updtDT} />}
				></Route>
				<Route
					exact
					path=""
					element={
						<AdList
							account={account}
							setAccount={setAccount}
							setArchived={(archived) => {
								setArchived(archived, () => {
									fetchData(archived);
								});
							}}
							archived={archived}
							setFetchedData={setFetchedData}
							setApiKey={setApiKey}
							setAds={setAds}
							localisationFilter={localisationFilter}
							setLocalisationFilter={setLocalisationFilter}
							ads={ads}
							updtDT={updtDT}
						/>
					}
				></Route>
			</Routes>
		</HashRouter>
	);
}

function emphasizeDescription(description) {
	return description.replace(
		/((un|deux|trois|quatre|cinq|six|sept|huit|neuf|dix|onze|douze|treize|quatorze|[123456789]{1,2})(( belles?)|( autres?))? ?(chambres?|studios?|locaux commerciaux))|((un|deux|trois|quatre|cinq|six|sept|huit|neuf|dix|onze|douze|treize|quatorze|[123456789]{1,2})?( ?[Tt][123456789]))|(quartier|double sejour|double séjour|séjour double)|([^ ]+ [0-9]+ ?(mn|min) (de (la gare (RER .)?|[^ ]+|[^ ]+)))|((finir les |des |pas de |aucun )?travaux)|/gi,
		function (x) {
			return `<strong>${x}</strong>`;
		},
	);
}

function AdView({ ads, updtDT }) {
	const [dragActive, setDragActive] = useState(false);

	const params = useParams();
	let ad;
	ads.forEach(function (a) {
		if (a.uuid === params.uuid) {
			ad = a;
		}
	});

	useEffect(() => {
		if (ad) {
			document.title = `${eur("prix")(ad)} ${ad.surface}m² ${titlePrefix}`;
		}
	}, [ad]);

	// handle drag events
	const handleDrag = function (e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActive(true);
		} else if (e.type === "dragleave") {
			setDragActive(false);
		}
	};
	// triggers when file is dropped
	const handleDrop = function (e) {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			updtDT(ad.uuid, "attachments", e.dataTransfer.files);
		}
	};

	return (
		<div
			onDragEnter={handleDrag}
			onDragLeave={handleDrag}
			onDragOver={handleDrag}
			onDrop={handleDrop}
			className={classNames({
				"drag-active": dragActive,
			})}
		>
			<div className="drop-area">Drop files here !</div>
			<div className="non-drop-area">
				<a href="#/">Liste</a>
				{ad ? (
					<>
						<Table single={true} data={[ad]} updtDT={updtDT} />
						<div className="ad-detail">
							<div
								className="multiline"
								dangerouslySetInnerHTML={{
									__html: emphasizeDescription(ad.description),
								}}
							/>
							<div className="photos">
								{ad.localPictures.map(function (picture) {
									return (
										<div className="photo" key={picture}>
											<img src={host(`pictures/${picture}`)} alt="" />
										</div>
									);
								})}
							</div>
							{ad.attachments != null ? (
								<div className="attachments">
									{ad.attachments.map(function (attachment) {
										return (
											<div className="attachment" key={attachment.path}>
												<img
													src={host(`attachments/${attachment.path}`)}
													alt=""
												/>
											</div>
										);
									})}
								</div>
							) : null}
						</div>
					</>
				) : (
					<h1>Not found</h1>
				)}
			</div>
		</div>
	);
}

function RecherchesLBC() {
	return null;
	// return (
	// 	<div className="link-group">
	// 		Recherches :
	// 		{[
	// 			{
	// 				url: "https://www.leboncoin.fr/recherche?category=9&locations=%C3%89vry_91000__48.62468_2.42977_3000&owner_type=all&immo_sell_type=old&real_estate_type=1,2,5&square=60-max#xtor=ES-3999-[MYSRCH]--[see_results_top]",
	// 				name: "Évry",
	// 			},
	// 			{
	// 				url: "https://www.leboncoin.fr/recherche?category=9&locations=Corbeil-Essonnes_91100__48.61264_2.48233_4831&owner_type=all&immo_sell_type=old&real_estate_type=1%2C2%2C5&square=60-max",
	// 				name: "Corbeil-Essonnes",
	// 			},
	// 			{
	// 				url: "https://www.leboncoin.fr/recherche?category=9&locations=Les%20Ulis_91940__48.68166_2.16944_3143&owner_type=all&immo_sell_type=old&real_estate_type=1%2C2%2C5&square=60-max",
	// 				name: "Les Ulis",
	// 			},
	// 		].map(function ({ url, name }, i) {
	// 			return (
	// 				<a key={i} href={url}>
	// 					{name}
	// 				</a>
	// 			);
	// 		})}
	// 	</div>
	// );
}

const favoriteLocalisations = [
	// { code: "91000", town: "Évry" },
	// { code: "91940", town: "Les Ulis" },
	// { code: "91100", town: "Corbeil-Essonnes" },
	// { code: "91150", town: "Étampes" },
	// { code: "27000", town: "Evreux" },
	// { code: "76000", town: "Rouen " },
	{ code: "42000", town: "Saint-Etienne" },
];

function AdList({
	localisationFilter,
	setLocalisationFilter,
	ads,
	updtDT,
	account,
	setAccount,
	setFetchedData,
	setApiKey,
	setAds,
	setArchived,
	archived,
}) {
	useEffect(() => {
		document.title = titlePrefix;
	}, []);

	const locFilter = deburr(localisationFilter.toLowerCase());
	return (
		<>
			<div>
				<label>
					Localisation
					<input
						type="text"
						value={localisationFilter}
						onChange={(e) => setLocalisationFilter(e.target.value)}
					/>
				</label>
				<div className="btn-group">
					{favoriteLocalisations.map(function ({ code, town }) {
						return (
							<button
								className={classNames("selectable", {
									selected: localisationFilter === code,
								})}
								key={code}
								onClick={() => setLocalisationFilter(code)}
							>
								{town}
							</button>
						);
					})}
					<button onClick={() => setLocalisationFilter("")}>X</button>
				</div>
				<RecherchesLBC />
				<button onClick={() => setArchived(!archived)}>
					<label onClick={(e) => e.preventDefault()}>
						Archivés
						<input
							type="checkbox"
							checked={archived}
							onClick={(e) => e.preventDefault()}
							onChange={(e) => e.preventDefault()}
						/>
					</label>
				</button>
				<div className="account-info">
					<b>{account} </b>
					<button
						onClick={() => {
							setFetchedData(false);
							setApiKey("");
							setAds([]);
							setAccount("");
						}}
						className="secondary"
					>
						Déconnexion
					</button>
				</div>
			</div>
			<Table
				localisationFilter={localisationFilter}
				data={ads.filter(function (ad) {
					if (localisationFilter) {
						if (ad.localisation_deburred.indexOf(locFilter) === -1) {
							return false;
						}
					}
					return true;
				})}
				updtDT={updtDT}
			/>
		</>
	);
}

export default App;
